<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nUser"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nUser"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'USER')"
                                :title="getI18n(i18nKey, 'TITLES.user')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'USER')  && !isTenantAdmin"
                                :title="getI18n(i18nKey, 'TITLES.user')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="users-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <!-- informações -->
                    <b-tab :title="i18nInformation">
                        <b-form-row>
                            <b-col md="12">
                                <b-form-row>
                                    <!-- Name -->
                                    <b-col md="4">
                                        <b-form-group
                                            class="required label-pdv"
                                            :label="i18nName"
                                            label-for="modal-name"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_name'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-name"
                                                v-model.trim="
                                                    $v.userInformation.name.$model
                                                "
                                                class="input-pdv-blue"
                                                name="name"
                                                aria-describedby="input-1-live-feedback"
                                                type="text"
                                                :disabled="!isEdit || submitLoading"
                                                :state="
                                                    validateField(
                                                        'name',
                                                        'userInformation'
                                                    )
                                                "
                                                :placeholder="i18nName"
                                                autofocus
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- Email -->
                                    <b-col md="4">
                                        <b-form-group
                                            class="required label-pdv"
                                            :label="i18nEmail"
                                            label-for="modal-email"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_email'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-email"
                                                v-model.trim="
                                                    $v.userInformation.email.$model
                                                "
                                                class="input-pdv-blue"
                                                name="email"
                                                aria-describedby="input-1-live-feedback"
                                                type="email"
                                                autocomplete="off"
                                                :disabled="isUserCreated || !isEdit || submitLoading"
                                                :state="
                                                    validateField(
                                                        'email',
                                                        'userInformation'
                                                    )
                                                "
                                                :placeholder="i18nEmail"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- Phone -->
                                    <b-col md="4">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="i18nPhone"
                                            label-for="modal-phone"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_phone'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-phone"
                                                v-model.trim="userInformation.phone"
                                                class="input-pdv-blue"
                                                name="phone"
                                                aria-describedby="input-1-live-feedback"
                                                type="text"
                                                :disabled="!isEdit || submitLoading"
                                                :placeholder="i18nPhone"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                <b-form-row>
                                    <!-- Password -->
                                    <b-col md="3">
                                        <b-form-group
                                            :class="isUserCreated ? 'label-pdv' : 'required label-pdv'"
                                            :label="i18nPassword"
                                            label-for="modal-password"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_password_length'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-password"
                                                v-model.trim="
                                                    $v.userInformation.password.$model
                                                "
                                                class="input-pdv-blue"
                                                name="password"
                                                type="password"
                                                autocomplete="new-password"
                                                aria-describedby="input-1-live-feedback"
                                                :placeholder="
                                                    getI18n(
                                                        'PLACEHOLDERS.password_length'
                                                    )
                                                "
                                                :state="
                                                    validateField(
                                                        'password',
                                                        'userInformation'
                                                    )
                                                "
                                                :disabled="!isEdit || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- Password Confirmed -->
                                    <b-col md="3">
                                        <b-form-group
                                            :class="isUserCreated ? 'label-pdv' : 'required label-pdv'"
                                            :label="i18nPasswordConfirmation"
                                            label-for="modal-password-confirmation"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_password_match'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-password-confirmation"
                                                v-model.trim="
                                                    $v.userInformation
                                                        .password_confirmation
                                                        .$model
                                                "
                                                name="password_confirmation"
                                                class="input-pdv-blue"
                                                type="password"
                                                autocomplete="new-password"
                                                aria-describedby="input-1-live-feedback"
                                                :placeholder="
                                                    i18nPasswordConfirmation
                                                "
                                                :state="
                                                    validateField(
                                                        'password_confirmation',
                                                        'userInformation'
                                                    )
                                                "
                                                :disabled="!isEdit || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="3">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="i18nGender"
                                            label-for="modal-gender"
                                            :invalid-feedback="getI18n('ERROR_CODES.invalid_gender')"
                                        >
                                            <translated-multiselect
                                                id="modal-gender"
                                                v-model="gender"
                                                :options="genders"
                                                track-by="value"
                                                label="text"
                                                :disabled="!isEdit || submitLoading"
                                                @input="changeModalGender"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- Birthday -->
                                    <b-col md="3">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="i18nBirthday"
                                            label-for="modal-birthday"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_birthday'
                                                )
                                            "
                                        >
                                            <date-picker
                                                :value.sync="userInformation.birthday"
                                                :disabled="!isEdit || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                <b-form-row>
                                    <!-- Cpf -->
                                    <b-col md="3">
                                        <b-form-group
                                            v-mask="'###.###.###-##'"
                                            class="label-pdv"
                                            :label="i18nCpf"
                                            label-for="modal-cpf"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_cpf'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-cpf"
                                                v-model.trim="$v.userInformation.cpf.$model"
                                                class="input-pdv-blue"
                                                name="cpf"
                                                aria-describedby="input-1-live-feedback"
                                                type="text"
                                                maxlength="14"
                                                autocomplete="off"
                                                :state="validateField('cpf', 'userInformation')"
                                                :disabled="!isEdit || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- RG -->
                                    <b-col md="3">
                                        <b-form-group
                                            class="label-pdv"
                                            label="RG"
                                            label-for="modal-cpf"
                                            :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_rg'
                                                )
                                            "
                                        >
                                            <b-form-input
                                                id="modal-rg"
                                                v-model.trim="userInformation.rg"
                                                class="input-pdv-blue"
                                                name="rg"
                                                aria-describedby="input-1-live-feedback"
                                                type="text"
                                                autocomplete="off"
                                                :disabled="!isEdit || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- idioma -->
                                    <b-col md="4">
                                        <b-form-group
                                            class="required label-pdv"
                                            :label="i18nLanguage"
                                            label-for="modal-language"
                                        >
                                            <multi-select
                                                id="modal-language"
                                                v-model="language"
                                                :options="languages"
                                                label="label"
                                                :class="
                                                    validationClass(
                                                        $v.userInformation.language
                                                    )
                                                "
                                                :searchable="true"
                                                :disabled="!isEdit || submitLoading"
                                                @input="changeModalLanguage"
                                            ></multi-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="2">
                                        <b-form-group
                                            class="label-pdv"
                                            :label="getI18n(i18nCommon, 'active')"
                                            label-for="active"
                                        >
                                            <b-form-checkbox
                                                id="active"
                                                v-model="userInformation.active"
                                                size="lg"
                                                switch
                                                :disabled="!isEdit || submitLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <!-- endereço -->
                    <b-tab :title="getI18n(i18nCommon, 'address')">
                        <google-auto-complete
                            id="autocomplete_address"
                            ref="gautocomplete"
                            :modal="gModal"
                            :required="false"
                            :disabled="!isEdit || submitLoading"
                        />
                    </b-tab>

                    <!-- contrato -->
                    <b-tab :title="getI18n(i18nCommon, 'contract')">
                        <b-form-row>
                            <!-- Data de admissao -->
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="getI18n(i18nCommon, 'admission_date')"
                                    label-for="modal-admission-date"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_admission_date')"
                                >
                                    <date-picker
                                        id="modal-admission-date"
                                        :child-class="validationClass($v.userContract.admission_date)"
                                        :value.sync="$v.userContract.admission_date.$model"
                                        :max-date="new Date()"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Tipo contrato -->
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="getI18n(i18nCommon, 'contract_type')"
                                    label-for="modal-contract-type"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_contract_type')"
                                >
                                    <multi-select
                                        id="modal-contract-type"
                                        v-model="contract"
                                        :options="contracts"
                                        label="label"
                                        :class="validationClass($v.userContract.contract_type)"
                                        :searchable="true"
                                        :disabled="!isEdit || submitLoading"
                                        @input="changeModalContractType"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="getI18n('SECURITY', 'TITLES.role')"
                                    label-for="role-modal"
                                >
                                    <multiSelectWithService
                                        :id="'role-modal'"
                                        ref="parentRoleSelect"
                                        v-model="userContract.role"
                                        :input-function="
                                            $v.userContract.role_id.$touch
                                        "
                                        :select-class="
                                            validationClass(
                                                $v.userContract.role_id
                                            )
                                        "
                                        :service="'roles'"
                                        label="alias"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!isEdit || isTenantAdmin || submitLoading"
                                        @input="changeRoleId"
                                    ></multiSelectWithService>
                                </b-form-group>
                            </b-col>
                            <!-- resignation_date -->
                            <b-col md="4">
                                <b-form-group
                                    class="label-pdv"
                                    :label="
                                        getI18n(i18nCommon, 'resignation_date')
                                    "
                                    label-for="modal-resignation_date"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_resignation_date')
                                    "
                                >
                                    <date-picker
                                        :value.sync="userContract.resignation_date"
                                        :max-date="new Date()"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- workdays -->
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="getI18n(i18nCommon, 'workday')"
                                    label-for="workday-modal"
                                >
                                    <multiSelectWithService
                                        :id="'workday-modal'"
                                        ref="parentWorkdaySelect"
                                        v-model="userContract.workday"
                                        :input-function="
                                            $v.userContract.workday_id.$touch
                                        "
                                        :select-class="
                                            validationClass(
                                                $v.userContract.workday_id
                                            )
                                        "
                                        :service="'workdays'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!isEdit || submitLoading"
                                        @input="changeWorkdayId"
                                    ></multiSelectWithService>
                                </b-form-group>
                            </b-col>
                            <!-- Timezone -->
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nTimezone"
                                    label-for="modal-timezone"
                                >
                                    <multi-select
                                        id="modal-timezone"
                                        v-model="timezone"
                                        :options="timezones"
                                        label="label"
                                        :class="
                                            validationClass(
                                                $v.userContract.timezone
                                            )
                                        "
                                        :searchable="true"
                                        :disabled="!isEdit || submitLoading"
                                        @input="changeModalTimezone"
                                    ></multi-select>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="4">
                                <b-form-group
                                    class="label-pdv"
                                    :label="getI18n(i18nCommon, 'imei')"
                                    label-for="modal-imei"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_imei')
                                    "
                                >
                                    <b-form-input
                                        id="modal-imei"
                                        v-model.trim="userContract.imei"
                                        class="input-pdv-blue"
                                        name="imei"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :placeholder="
                                            getI18n(i18nCommon, 'imei')
                                        "
                                        autocomplete="off"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-6 col-md-1">
                                <b-form-group
                                    class="label-pdv"
                                    :label="getI18n(i18nCommon, 'imei_active')"
                                    label-for="imei_active"
                                >
                                    <b-form-checkbox
                                        id="imei_active"
                                        v-model="userContract.imei_active"
                                        size="lg"
                                        switch
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <!-- custos -->
                    <b-tab
                        v-if="isUserCreated"
                        :title="i18nCost"
                    >
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nName"
                                    class="required label-pdv"
                                    label-for="modal-cost-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-cost-name"
                                        v-model.trim="cost.name"
                                        class="input-pdv-blue"
                                        name="cost-name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        autocomplete="off"
                                        :disabled="submitCostLoading"
                                        :state="validateField('name', 'cost')"
                                    />
                                </b-form-group>
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nType"
                                    label-for="modal-cost-type"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <multi-select
                                        id="modal-cost-type"
                                        v-model="cost.type"
                                        :class="validationClass($v.cost.type)"
                                        :options="costs"
                                        label="label"
                                        :searchable="true"
                                        :disabled="submitCostLoading"
                                    ></multi-select>
                                </b-form-group>
                                <b-form-group
                                    :label="i18nMonthlyValue"
                                    label-for="modal-cost-monthly-value"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_number')"
                                >
                                    <money
                                        id="modal-cost-monthly-value"
                                        v-model.trim="cost.value"
                                        v-bind="money"
                                        name="cost-monthly-value"
                                        class="form-control input-pdv-blue"
                                        :class="validationClass($v.cost.value)"
                                        aria-describedby="input-1-live-feedback"
                                        :disabled="submitCostLoading"
                                        :state="validateField('value', 'cost')"
                                    />
                                </b-form-group>
                                <add-button
                                    ref="addCostButton"
                                    :busy="submitCostLoading"
                                    @onClick="handleAddCost"
                                />
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="userInformation.costs"
                                    :fields="costFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    totalizing-field="value"
                                    @onClickDelete="handleRemoveCost"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isUserCreated ? tabIndex !== 3 : tabIndex !== 2"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isUserCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import {
    required,
    requiredIf,
    minLength,
    email,
    sameAs,
    decimal,
    minValue,
} from 'vuelidate/lib/validators'
import { cpf } from 'cpf-cnpj-validator';
import { Money } from 'v-money'
import UsersService from '@src/services/UsersService.js'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import multiSelectWithService from '@src/components/multiSelectWithService'
import multiSelect from '@components/multiSelect'
import googleAutoComplete from '@components/googleAutoComplete'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import timezones from '@assets/resources/timezones'
import statesWithCities from '@src/assets/resources/statesWithCities'
import languages from '@src/assets/resources/languages'
import contracts from '@assets/resources/contracts'
import costs from '@assets/resources/costs'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import DatePicker from '@/src/components/DatePicker'
import TranslatedMultiselect from '@/src/components/translatedMultiselect.vue'
import genders from '@src/mixins/genders'
import { i18n } from '@src/i18n'

const i18nKey = 'USERS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Money,
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        multiSelectWithService,
        multiSelect,
        googleAutoComplete,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DataModeVuetable,
        DatePicker,
        TranslatedMultiselect,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        vuetableFormatter,
        genders
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        // eslint-disable-next-line
        modal: Object,
        // eslint-disable-next-line
        googleModal: Object,
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            userInformation: {},
            userContract: {},
            gModal: this.googleModal,
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            submitCostLoading: false,
            tabIndex: 0,
            isTenantAdmin: false,
            timezones,
            statesWithCities,
            languages,
            contracts,
            costs,
            gender: null,
            timezone: null,
            language: null,
            contract: null,
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: i18n.t('CURRENCY.suffix'),
                precision: 2,
                masked: false,
            },
            costFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'type',
                    label: this.getI18n(i18nKey, 'FIELDS.type'),
                    sortable: true,
                    formatter: this.formatCostType,
                },
                {
                    key: 'value',
                    label: this.getI18n(i18nKey, 'FIELDS.monthly_value'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            cost: {
                name: '',
                type: '',
                value: 0,
            },
        }
    },
    validations: {
        userInformation: {
            name: {
                required,
                minLength: minLength(3),
            },
            email: {
                required,
                email,
            },
            cpf: {
                isValid(value) {
                    return value ? cpf.isValid(value.replace(/[^\d]+/g, '')) : true;
                },
            },
            password: {
                required: requiredIf(function (modal) {
                    return !this.isUserCreated
                }),
                minLength: minLength(6),
            },
            password_confirmation: {
                sameAsPassword: sameAs('password'),
            },
            active: {
                required,
            },
            language: {
                required,
            },
        },
        userContract: {
            admission_date: {
                required,
            },
            contract_type: {
                required,
            },
            role_id: {
                required,
            },
            workday_id: {
                required,
            },
            timezone: {
                required,
            },
        },
        cost: {
            name: {
                required,
                minLength: minLength(3),
            },
            type: {
                required,
            },
            value: {
                required,
                decimal,
                minValue: minValue(0.01)
            },
        }
    },
    computed: {
        isUserCreated() {
            return this.userInformation && this.userInformation.id > 0
        },
        i18nTitleModal() {
            if (this.isUserCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nUser}`
                }
                return this.userInformation.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_user')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nPhone() {
            return this.getI18n(i18nCommon, 'phone')
        },
        i18nBirthday() {
            return this.getI18n(i18nCommon, 'birthday')
        },
        i18nCpf() {
            return this.getI18n(i18nCommon, 'cpf')
        },
        i18nPassword() {
            return this.getI18n(i18nCommon, 'password')
        },
        i18nPasswordConfirmation() {
            return this.getI18n(i18nCommon, 'password_confirmation')
        },
        i18nTimezone() {
            return this.getI18n(i18nCommon, 'timezone')
        },
        i18nLanguage() {
            return this.getI18n(i18nCommon, 'language')
        },
        i18nType() {
            return this.getI18n(i18nKey, 'FIELDS.type')
        },
        i18nMonthlyValue() {
            return this.getI18n(i18nKey, 'FIELDS.monthly_value')
        },
        i18nGender() {
            return this.getI18n(i18nKey, 'FIELDS.gender')
        },
        i18nUser() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.user',
                modifier: 1,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nCost() {
            return this.getI18nModified({
                prefix: 'COMMON.cost',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isUserCreated ? 'edited' : 'created'
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.userInformation.$touch()
                    if (this.$v.userInformation.$invalid) {
                        event.preventDefault();
                    }
                }
                else if (oldTabIndex === 1 && newTabIndex > oldTabIndex) {
                    this.$refs.gautocomplete.$v.gModal.$touch()
                    if (this.$refs.gautocomplete.$v.gModal.$invalid) {
                        event.preventDefault();
                    }
                }
                else if (oldTabIndex === 2 && newTabIndex > oldTabIndex) {
                    this.$v.userContract.$touch()
                    if (this.$v.userContract.$invalid) {
                        event.preventDefault();
                    }
                }

                if (newTabIndex > oldTabIndex && (newTabIndex - oldTabIndex > 1)) {
                    event.preventDefault();
                    this.tabIndex = oldTabIndex + 1
                }
            }
        },
        changeModalTimezone(option) {
            this.$v.userContract.timezone.$model = option.id
            this.$v.userContract.timezone.$touch()
        },
        changeModalLanguage(option) {
            this.$v.userInformation.language.$model = option.id
            this.$v.userInformation.language.$touch()
        },
        changeModalContractType(option) {
            this.$v.userContract.contract_type.$model = option.id
            this.$v.userContract.contract_type.$touch()
        },
        changeRoleId(e) {
            this.userContract.role_id = e ? e.id : null
            this.clearResponseError('role_id', 'userContract')
        },
        changeWorkdayId(e) {
            this.userContract.workday_id = e ? e.id : null
            this.clearResponseError('workday_id', 'userContract')
        },
        changeModalGender(option) {
            this.userInformation.gender =option ? option.value : null
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.submitCostLoading = false
            this.tabIndex = 0
            this.userInformation = {
                id: this.modal.id,
                name: this.modal.name,
                email: this.modal.email,
                password: this.modal.password,
                active: this.modal.active,
                password_confirmation: this.modal.password_confirmation,
                phone: this.modal.phone,
                gender: this.modal.gender,
                birthday: this.modal.birthday,
                cpf: this.modal.cpf,
                rg: this.modal.rg,
                language: this.modal.language,
                costs: this.modal.costs,
            }
            this.userContract = {
                admission_date: this.modal.admission_date,
                resignation_date: this.modal.resignation_date,
                imei: this.modal.imei,
                imei_active: this.modal.imei_active,
                contract_type: this.modal.contract_type,
                timezone: this.modal.timezone,
                workday: this.modal.workday,
                workday_id: this.modal.workday_id,
                role: this.modal.role,
                role_id: this.modal.role_id,
            }
            this.gModal = this.googleModal
            this.cost = Object.assign(this.cost, {
                name: '',
                type: '',
                value: 0,
            })

            this.gender = this.genders.find((gender) => gender.value === this.userInformation.gender)
            this.language = languages.find((language) => language.id === this.userInformation.language)
            this.timezone = timezones.find((timezone) => timezone.id === this.userContract.timezone)
            this.contract = contracts.find((contract) => contract.id === this.userContract.contract_type)
            if (Array.isArray(this.userContract.role) && this.userContract.role.length > 0) {
                this.userContract.role = this.userContract.role[0]
                this.userContract.role_id = this.userContract.role.id
            }
            this.isTenantAdmin = this.userContract.role ? this.userContract.role.name === 'admin entidade' : false

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            var self = this
            Object.keys(this.modal).forEach(function (key, index) {
                if (key === 'active') {
                    self.modal[key] = true
                } else if (key === 'imei_active') {
                    self.modal[key] = false
                } else if (key === 'costs') {
                    self.modal[key] = []
                } else {
                    self.modal[key] = null
                }
            })

            Object.keys(this.gModal).forEach(function (key, index) {
                self.gModal[key] = null
            })

            this.cost = Object.assign(this.cost, {
                name: '',
                type: '',
                value: 0,
            })

            this.$v.userInformation.$reset()
            this.$v.userContract.$reset()
            this.$v.cost.$reset()
        },
        handleEdit() {
            this.editLoading = true

            this.isEdit = true
            this.tabIndex = 0
            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.userInformation.$touch()
            this.$v.userContract.$touch()
            this.$refs.gautocomplete.$v.gModal.$touch()
            if (!this.$v.userInformation.$invalid && !this.$v.userContract.$invalid && !this.$refs.gautocomplete.$v.gModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const userInfo = {
                name: this.userInformation.name,
                email: this.userInformation.email,
                password: this.userInformation.password,
                active: this.userInformation.active,
                password_confirmation: this.userInformation.password_confirmation,
                phone: this.userInformation.phone,
                gender: this.userInformation.gender,
                birthday: this.userInformation.birthday,
                cpf: this.userInformation.cpf ? this.userInformation.cpf.replace(/[^\d]+/g, '') : null,
                rg: this.userInformation.rg,
                language: this.userInformation.language,
                costs: this.userInformation.costs,
                admission_date: this.userContract.admission_date,
                resignation_date: this.userContract.resignation_date,
                imei: this.userContract.imei,
                imei_active: this.userContract.imei_active,
                contract_type: this.userContract.contract_type,
                timezone: this.userContract.timezone,
                workday_id: this.userContract.workday_id,
                role_id: this.userContract.role_id,
                lat: this.$refs.gautocomplete.gModal.latitude,
                route: this.$refs.gautocomplete.gModal.route,
                number: this.$refs.gautocomplete.gModal.number,
                long: this.$refs.gautocomplete.gModal.longitude,
                address: this.$refs.gautocomplete.gModal.address,
                long_name: this.$refs.gautocomplete.gModal.longName,
                short_name: this.$refs.gautocomplete.gModal.shortName,
                district: this.$refs.gautocomplete.gModal.district,
                place_id: this.$refs.gautocomplete.gModal.placeId,
                zip_code: this.$refs.gautocomplete.gModal.postalCode,
                complement: this.$refs.gautocomplete.gModal.complement,
                city_id: this.$refs.gautocomplete.gModal.city_id ?? null,
                city: this.$refs.gautocomplete.gModal.city ? this.$refs.gautocomplete.gModal.city.label : null,
                state: this.$refs.gautocomplete.gModal.state ? this.$refs.gautocomplete.gModal.state.label : null,
            }

            if (this.isUserCreated && !userInfo.password) {
                delete userInfo.password
                delete userInfo.password_confirmation
            }

            const operation = this.isUserCreated
                ? UsersService.update(this.userInformation.id, userInfo)
                : UsersService.create(userInfo)

            const response = await operation
                .then((response) => response)
                .catch((errors) => errors)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nUser, this.modalOperation, 0)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nUser, response.data.errors)
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteUser)
        },
        async deleteUser(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.userInformation.$touch()
            this.$v.userContract.$touch()

            this.deleteLoading = true
            const response = await UsersService.delete(this.userInformation.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nUser, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        handleAddCost() {
            this.$v.cost.$touch()
            if (!this.$v.cost.$invalid && !this.submitCostLoading) {
                this.submitCostLoading = true

                this.userInformation.costs.push({
                    id: 0,
                    name: this.cost.name,
                    type: this.cost.type.id,
                    value: this.cost.value,
                })

                Object.assign(this.cost, {
                    name: '',
                    type: '',
                    value: 0,
                })

                this.resetValidation()

                this.submitCostLoading = false
            }
        },
        async handleRemoveCost(id) {
            const index = this.userInformation.costs.findIndex(find => find.id === id);

            if (index > -1) {
                this.userInformation.costs.splice(index, 1);
            }
        },
    },
}
</script>
